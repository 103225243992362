<template>
  <a-row v-if="skeletonLoader" :gutter="22" >
    <a-col :xs="24" :sm="12" :md="8" :lg="8" :xl="xl" v-for="index in 3" :key="index">
      <ArticleCardSkeleton/>
    </a-col>
  </a-row>
  <a-row v-if="!skeletonLoader && contentLength >= 1 && isLoggedInUser" :gutter="22">
    <button class="add-media-btn" @click="openAddMediaModal()">
      <i class="icon icon-add_plus"></i>
    </button>
  </a-row>
  <a-row v-if="!skeletonLoader && contentLength == 0 && isLoggedInUser" :gutter="22" >
    <button @click="openAddMediaModal()" class="add-content-btn"><i class="icon icon-add_plus"></i> Add content</button>
  </a-row>
  <div v-if="!skeletonLoader && contentLength == 0 && !isLoggedInUser" class="message">
    <p>{{noContentFoundText}}</p>
  </div>
  <a-row v-else-if="!skeletonLoader" :gutter="[18, 18]">
   <template v-if="!isArchiveMedia">
      <a-col
        v-for="video in media"
        :key="video"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="xl"
      >
        <MediaCard 
          :videoUrl="video.link_content ? video.link_content : ''"
          :videoFileName="video.title"
          :id="video.id"
          :for="'Link'"
          :deleteBtn="true"
        />
      </a-col>
  </template>
  <template v-else>
      <a-col
        v-for="media in archiveMedia"
        :key="media"
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="xl"
      >
      <template v-if="media.content_type !== 'books'">
        <MediaCard 
          :videoUrl="media?.link ?? ''"
          :videoFileName="media?.title ?? ''"
          :thumbnail="media?.thumbnail"
          :id="media.id"
          :for="'Link'"
          :deleteBtn="true"
          :isArchiveMedia="true"
          @deleteArchiveContent="deleteArchiveContent"
        />
      </template>
      <template v-else>
        <MediaCard 
          :videoUrl="media?.link ?? ''"
          :videoFileName="media?.title ?? ''"
          :thumbnail="media?.thumbnail"
          :id="media.id"
          :for="'Book'"
          :deleteBtn="true"
          :isArchiveMedia="true"
          @deleteArchiveContent="deleteArchiveContent"
        />
      </template>
      </a-col>
  </template>
  </a-row>
  <UploadMediaModal 
   :visible="visibleUploadDocumentModal"
   :folderId="folderId"
   :modalTitle="isBookContent ? 'Book' : isVideoContent ? 'Link' : 'Podcast'"
   :isArchiveContentModal="isArchiveMedia"
   @closeModal="closeAddDocumentModal()"
  />
</template>

<script>
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"
import MediaCard from "./MediaCard.vue";
import UploadMediaModal from "./UploadDocumentModal.vue";
import {mapGetters, mapActions} from "vuex";
// import BookCard from "./BookCard.vue";

export default {
  data() {
    return {
      visibleUploadDocumentModal: false,
      xl: 12
    };
  },
  props:{
    archiveFolderId:{
      default: ()=>(''),
      type: String,
    },
    isArchiveMedia:{
      default: ()=>(false),
      type: Boolean,
    },
    isVideoContent:{
      default: ()=>(true),
      type: Boolean,
    },
    collaborateToggle: {
      type: Boolean,
      default:()=>true
    },
    isTransitioning: {
      type: Boolean,
      default:()=>false
    },
    isBookContent:{
      default: () =>(false),
      type: Boolean,
    }
  },
  components: {
    ArticleCardSkeleton,
    MediaCard,
    UploadMediaModal,
    // BookCard
  },
  watch: {
    collaborateToggle: {
      handler() {
        this.updateXl();
      },
      immediate: true
    },
    isTransitioning: {
      handler() {
        this.updateXl();
      },
      immediate: true
    },
    isArchiveMedia: {
      handler(val) {
        if(val == true) {
          this.xl = 8;
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      folderContentList: "archive/folderContentList"
    }),
    skeletonLoader() {
      return this.$store.getters["folders/getFolderMediaContentLoader"];
    },
    media() {
      return this.$store.getters["folders/getFolderMediaContent"];
    },
    archiveMedia(){
      return this.folderContentList
    },
    folderId() {
      return this.isArchiveMedia && this.archiveFolderId ? this.archiveFolderId : this.$route.params.id
    },
    contentLength(){
      return this.isArchiveMedia ? this.archiveMedia.length : this.media.length
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    noContentFoundText(){
      if (this.isVideoContent)
        return "No videos found"
      else if (!this.isVideoContent && this.isBookContent)
        return "No books found"
      else
        return "No podcasts found"
    }
  },
  methods: {
    ...mapActions({
      deleteArchiveFoldersContent: 'archive/deleteArchiveFoldersContent'
    }),
    openAddMediaModal() {
      this.visibleUploadDocumentModal = true;
    },
    closeAddDocumentModal(){
      this.visibleUploadDocumentModal = false;
    },
    updateXl() {
      if (this.collaborateToggle && this.isTransitioning) {
        this.xl = 12;
      } else if (!this.collaborateToggle && !this.isTransitioning){
        this.xl = 8;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-media-btn {
  background-color: $color-primary;
  height: 4.2rem;
  width: 4.2rem;
  border: none;
  outline: none;
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  .icon {
    color: $color-white;
    font-size: 1.6rem;
  }
}
.add-content-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: $font-primary;
  height: fit-content;
  line-height: normal;
  padding: 0;
  border: none;
  outline: none;
  color: $color-black;
  margin: 5rem auto 0;
  cursor: pointer;
  .icon {
    font-size: 1.4rem;
    margin-right: 1rem;
    margin-bottom: 0.1rem;
  }
}
.message {
  p {
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-bottom: 0;
    line-height: 2.3rem;
    color: $color-black;
    margin-bottom: 3rem;
    text-align: center;
  }
}
</style>
