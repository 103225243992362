<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="drawerVisibility"
    wrapClassName="activity-log-drawer"
    width="43.2rem"
    title="Activity Log"
    @close="$emit('close-drawer')"
  >
    <div class="activity-log-drawer-container">
      <div class="activity-log-drawer-container__body">
        <div class="activity-log-drawer-container__body--students" id="allActivityLog">
          <div v-if="isFolderActivityLoading" class="skeleton">
            <ConnectionSkeletonLoader v-for="index in 5" :key="index" />
          </div>
          <div
            v-else
            class="student-card"
            v-for="activity in getFolderActivity"
            :key="activity"
            @click="redirectToFolder(activity)"
          >
            <div class="student-card__image">
              <img :src="activity.user.profile_picture" />
            </div>
            <div class="student-card__details">
              <div class="name-time">
                <h1>
                  {{ activity.user.full_name }}
                </h1>
                <p>{{ activity.data.time }}</p>
              </div>
              <h4>
                {{ activity.data.message }}.
              </h4>
            </div>
          </div>
        </div>
        <div class="api-call-loader" v-if="fetchActivityLoading">
          <the-loader/>
        </div>
        <p
          v-if="!isFolderActivityLoading && getFolderActivity.length == 0"
          class="activity-log-drawer-container__body--message"
        >
          No recent activity found
        </p>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import TheLoader from "../BaseComponents/TheLoader.vue";
import ConnectionSkeletonLoader from "../ProfilePageComponentsV2/SkeletonLoaders/ConnectionSkeletonLoader.vue";

export default {
  emits: ["close-drawer"],
  data() {
    return {
      searchQuery: "",
      debounceTimer: null,
      fetchActivityLoading: false,
    };
  },
  props: {
    drawerVisibility: Boolean,
  },
  components: {
    ConnectionSkeletonLoader,
    TheLoader
  },
  watch: {
    drawerVisibility: {
      async handler(val) {
        if (val) {
          this.$store.commit("folders/SET_FOLDER_ACTIVITY_SKELETON", true); 
          await this.$store.dispatch("folders/fetchFolderActivity", this.instituteId);
          const container = document.querySelector("#allActivityLog");
          if (container) {
            container.addEventListener("scroll", async () => {
              const userScrollHeight = container.scrollTop + container.clientHeight;
              const threshold = userScrollHeight + container.scrollHeight * 0;
              if (threshold - container.scrollHeight > -2) {
                this.fetchActivityLoading = true;
                this.$store.commit("folders/SET_FOLDER_ACTIVITY_SKELETON", false);  
                await this.$store.dispatch("folders/fetchFolderActivity", this.instituteId);
                this.fetchActivityLoading = false;
              }
            });
          } else {
            console.error('Element with id "allActivityLog" not found.');
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    instituteId() {
      return this.$store.getters["profile/userInformation"].profile.institute
        .id;
    },
    isFolderActivityLoading() {
      return this.$store.getters["folders/getFolderActivityLoader"];
    },
    getFolderActivity() {
      return this.$store.getters["folders/getFolderActivity"];
    },
  },
  mounted() {
    // this.$store.dispatch("folders/fetchFolderActivity", this.instituteId);
  },
  methods: {
    redirectToFolder(folder) {
      this.$router.push(`/workspaces/institute/${folder.instance.folder_id}`);
      this.$emit('close-drawer')
    },
  },
};
</script>

<style lang="scss">
.activity-log-drawer {
  .ant-drawer-content-wrapper {
    overflow: hidden;
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.6rem 2.8rem !important;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            font-weight: 700;
            color: $color-black;
            line-height: 3.2rem;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 4rem;
            color: $color-black;
            opacity: 1;
            .anticon {
              svg {
                fill: $color-black;
              }
            }
          }
        }
        .ant-drawer-body {
          padding: 0;
          margin: 0;
          overflow: hidden;
          .activity-log-drawer-container {
            &__header {
              background-color: $color-white;
              padding: 0 2.6rem 2rem;
              .search-bar {
                width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                background-color: #e9e9e9;
                padding: 0 1rem;
                border-radius: 100px;
                .icon {
                  font-size: 1.5rem;
                  opacity: 1;
                  color: $color-primary;
                  margin-top: 0rem;
                }
                .search-input {
                  font-size: 1.2rem;
                  font-family: $font-primary;
                  line-height: normal;
                  padding: 1.2rem 1rem;
                  border: none;
                  outline: none;
                  width: 100%;
                  background-color: transparent;
                  &::placeholder {
                    color: $color-black;
                    opacity: 0.5;
                  }
                }
              }
            }
            &__body {
              margin-top: 0.4rem;
              position: relative;
              &--students {
                position: relative;
                padding: 0 1.6rem;
                height: calc(100vh - 13rem);
                overflow-y: auto;
                margin: 0 1rem;
                overflow-x: hidden;
                .student-card {
                  display: flex;
                  align-items: flex-start;
                  padding: 1.6rem;
                  border: 1px solid #0000001a;
                  border-radius: 0.8rem;
                  background-color: #f1f1f1;
                  margin-bottom: 1.2rem;
                  min-height: 9.1rem;
                  &__image {
                    width: 3.8rem;
                    height: 3.8rem;
                    margin-right: 0.9rem;
                    border-radius: 100%;
                    background-color: #d4d4d4;
                    img {
                      height: 100%;
                      width: 100%;
                      object-fit: contain;
                      border-radius: 100%;
                    }
                  }
                  &__details {
                    width: calc(100% - 3.8rem);
                    .name-time {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      margin-bottom: 0.7rem;
                      h1 {
                        font-size: 1.4rem;
                        font-family: $font-primary-medium;
                        font-weight: 500;
                        color: $color-black;
                        line-height: 1.8rem;
                        margin-bottom: 0;
                      }
                      p {
                        font-size: 1rem;
                        font-family: $font-primary;
                        color: $color-black;
                        line-height: 1.3rem;
                        opacity: 0.5;
                        margin-bottom: 0;
                      }
                    }
                    h4 {
                      font-size: 1.2rem;
                      font-family: $font-primary;
                      color: #757575;
                      line-height: 1.6rem;
                      margin-bottom: 0;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 2; /* number of lines to show */
                              line-clamp: 2; 
                      -webkit-box-orient: vertical;
                    }
                  }
                }
                .skeleton {
                  margin: 0;
                  .connection-skeleton-loader {
                    margin: 0.5rem 0 !important;
                    .ant-skeleton-header {
                      .ant-skeleton-avatar {
                        border-radius: 20%;
                      }
                    }
                  }
                }
                &::-webkit-scrollbar {
                  width: 5px;
                  height: 5px;
                }

                &::-webkit-scrollbar-track {
                  background-color: transparent;
                  border-radius: 0.8rem;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 0.8rem;
                  height: 5px;
                  width: 5px;
                }
              }
              &--message {
                color: $color-black;
                font-size: 1.4rem;
                margin-bottom: 0;
                line-height: normal;
                font-family: $font-primary;
                text-align: center;
              }
              .api-call-loader {
                  background-color: #ffffff9f;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 0;
                  right: 48%;
                  height: calc(100vh - 13rem);
                  width: calc(100% - 3.5rem);
                  transform: translateX(50%);
                  .ant-spin {
                    .anticon {
                      font-size: 3rem !important;
                    }
                  }
                }
            }
          }
        }
      }
    }
  }
}
</style>
