<template>
  <div class="folder-tree-section">
    <button v-if="permissions" @click="openCreateFolderModal()" class="folder-tree-section__add-btn">
      <i class="icon icon-add_plus"></i>
      Add Folder Below
    </button>
    <div v-if="searchingFolderList" class="folder-tree-section__skeleton">
      <a-skeleton active :title="null" :paragraph="{
          rows: 6,
          width: ['100%', '80%', '70%', '100%', '100%', '60%'],
        }"
      />
    </div>
    <a-tree v-else-if="folderList.length > 0" :tree-data="folderList" 
      show-icon
      auto-expand-parent="true"
      :key="reRender"
      v-model:selectedKeys="selectedKeys"
      :expandedKeys="expandedFolderKeys"
      >
      <template #folderIcon="{ folder}">
        <img @click="setFolderUrl(folder)" src="../../assets/icons/glocal-folder-icon.svg" alt="Icon" />
      </template>
      <template #title="{ folder }">
        <span @click="setFolderUrl(folder)" class="title">{{ folder.name }}</span>
        <a-dropdown
          v-if="permissions"
          @click.stop
          :trigger="['click']"
          placement="bottomRight"
        >
          <a class="ant-dropdown-link">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-if="folder.isParentFolder" @click.prevent="openCreateSubFolderModal(folder)" key="0">
                <a>Add Subfolder</a>
              </a-menu-item>
              <a-menu-item  @click.prevent="openEditFolderModal(folder)" key="2">
                <a>Edit Folder</a>
              </a-menu-item>
              <a-menu-item v-if="folder.isParentFolder && folder.parentFolderId === ''" @click.stop="archiveFolder(folder)" key="3">
                <a>Archive Folder</a>
              </a-menu-item>
              <a-menu-item @click.stop="openDeleteFolderModal(folder)" key="4">
                <a class="remove-btn">
                  Remove Folder
                </a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <button class="drag-btn">
          <img src="@/assets/icons/drag-handle-icon.svg" />
        </button>
      </template>
    </a-tree>
    <p v-if="!searchingFolderList && folderList.length === 0" class="folder-tree-section__message">{{ infoMessage }}</p>

  </div>

  <AddNewFolder 
   :visible="visibleCreateNewFolderModal"
   :action="'add'"
   :loader="isFolderBtnLoading"
   :modalTitle="modalTitle"
   @createFolder="createNewFolder"
   @closeModal="closeNewFolderModal()"
  />

  <EditFolderModal 
   :visible="visibleEditFolderModal"
   :action="'edit'"
   :folderToBeEdit="folderToBeEdit"
   :loader="isFolderBtnLoading"
   :modalTitle="modalTitle"
   @EditFolder="UpdateFolder"
   @closeModal="closeNewFolderModal()"
  />

  <delete-modal
    :visible="visibleRemoveFolderModal"
    @handle-close="visibleRemoveFolderModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="onRemoveFolder"
    @delete-record="deleteFolder"
  ></delete-modal>

  <message-toaster
   :visible="showMessageModal"
   :message="message"
   @handle-close="showMessageModal = false"
  >
  </message-toaster>

</template>

<script>
import AddNewFolder from "./AddNewFolder.vue";
import EditFolderModal from "./AddNewFolder.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import folderMixin from '../../mixins/folders.mixnin.js';

export default {
  components: {
    AddNewFolder,
    EditFolderModal,
    DeleteModal,
    MessageToaster,
  },
  data() {
    return {
      visibleCreateNewFolderModal: false,
      modalTitle: "",
      isFolderBtnLoading: false,
      showMessageModal: false,
      message: {},
      selectedKeys: [],
      parents: [],
      folderParentId: "",
      isNewFolder: false,
      isParentFolder: false,
      isChildFolder: false,
      reRender: 0,
      subFolderName: '',
      folderToBeRemoved: {},
      visibleRemoveFolderModal: false,
      folderToBeEdit: {},
      visibleEditFolderModal: false,
      onRemoveFolder: false,
      folderId: '',
    };
  },
  mixins: [folderMixin],
  props: {
    tabName: String,
  },
  computed: {
    searchingFolderList() {
      return this.$store.getters["folders/getFolderLoader"];
    },
    folderList: {
    get() {
      const folders = this.$store.getters["folders/getFolderList"].map((folder, index) => {
        const newFolder = {
          folder: {
            name: folder.name,
            id: folder.id,
            totalArticles: folder.total_articles,
            parentFolderId: "",
            isParentFolder: true,
            isChildFolder:  false,
            parents: [],
            topic: folder.topic,
          },
          topic: folder.topic,
          folder_tab: folder.folder_tab,
          slots: { icon: "folderIcon" },
          children: folder.child ? folder.child.map((child, childIndex) => {
            const children = {
              folder: {
                name: child.name,
                id: child.id,
                totalArticles: child.total_articles,
                parentFolderId: folder.id,
                isParentFolder: true,
                isChildFolder:  false,
                parents: [],
                topic: child.topic
              },
              slots: { icon: "folderIcon" },
              children: child.child.map((nestedChild, nestedIndex) => {
                const nestedChildren = {
                  slots: { icon: "folderIcon" },
                  folder: {
                    name: nestedChild.name,
                    id: nestedChild.id,
                    totalArticles: nestedChild.total_articles,
                    parentFolderId: folder.id,
                    childFolderId: child.id,
                    isParentFolder: false,
                    isChildFolder:  true,
                    parents: [],
                    topic: nestedChild.topic
                  },
                  topic: nestedChild.topic,
                };
                return nestedChildren;
              })
            };
            return children;
          }) : []
        };
        return newFolder;
      });

      folders.forEach((folder, index) => {
      this.generateKeys(folder, `0-${index}`);
    });

      return folders;
    },
    set(val) {
      this.$store.commit("folders/SET_PERSONAL_FOLDER_TREE", val);
    }
  },
  expandedFolderKeys() {
    const expandKeys = [];

    const collectKeys = (node) => {
      if (node.children && node.children.length > 0) {
        expandKeys.push(node.key);  // Add key if children exist
        node.children.forEach(child => collectKeys(child));  // Recursive call for children
      }
    };

    this.folderList.forEach(folder => collectKeys(folder));

    return expandKeys;
  },
    // folderList: {
    //   get() {
    //     const folders = this.$store.getters["folders/getFolderList"].map(folder => {
    //       const newFolder = {
    //         folder: {
    //           name: folder.name,
    //           id: folder.id,
    //           totalArticles: folder.total_articles,
    //           parentFolderId: "",
    //           isParentFolder: true,
    //           isChildFolder:  false,
    //           parents: [],
    //           topic: folder.topic,
    //         },
    //         topic: folder.topic,
    //         key: folder.id,
    //         folder_tab: folder.folder_tab,
    //         slots: { icon: "folderIcon" },
    //         children: folder.child ? folder.child.map(child => {
    //           const children = {
    //             folder: {
    //               name: child.name,
    //               id: child.id,
    //               totalArticles: child.total_articles,
    //               parentFolderId: folder.id,
    //               isParentFolder: true,
    //               isChildFolder:  false,
    //               parents: [],
    //               topic: child.topic
    //             },
    //             slots: { icon: "folderIcon" },
    //             key: child.id,
    //             children: child.child.map(nestedChild => {
    //               const nestedChildren = {
    //                 slots: { icon: "folderIcon" },
    //                 folder: {
    //                   name: nestedChild.name,
    //                   id: nestedChild.id,
    //                   totalArticles: nestedChild.total_articles,
    //                   parentFolderId: folder.id,
    //                   childFolderId: child.id,
    //                   isParentFolder: false,
    //                   isChildFolder:  true,
    //                   parents: [],
    //                   topic: nestedChild.topic
    //                 },
    //                 topic: nestedChild.topic,
    //                 key: nestedChild.id,
    //               }
    //               return nestedChildren;
    //             })
    //           }
    //           return children;
    //         }) : []
    //       }
    //       return newFolder;
    //     });
    //     return folders
    //   },
    //   set(val) {
    //     this.$store.commit("folders/SET_PERSONAL_FOLDER_TREE", val);
    //   }
    // },
    isInstitute() {
      return this.$store.getters["profile/userInformation"].profile.institute;
    },
    permissions() {
      if (this.tabName === 'Class Folders' && Object.keys(this.isInstitute).length == 0) {
        return false;
      }else if (this.tabName === 'Class Folders' && this.isInstitute?.permission === 'student') {
          return false;
      }
      return true;
      
    },
    infoMessage() {
      let msg = "";
      if (Object.keys(this.isInstitute).length == 0 && this.tabName === 'Class Folders') {
        msg = `You haven't joined any institute yet`;
      } else if (this.folderList.length == 0) {
        msg = 'No content found';
      }
      return msg;
    },
    archivedFolders() {
      return this.$store.getters['folders/getArchivedFolder'];
    },
    parentFolder() {
      return this.$store.getters["folders/getParentFolderDetails"];
    },
    childFolder() {
      return this.$store.getters["folders/getChildFolderDetails"];
    },
    nestedChildFolder() {
      return this.$store.getters["folders/getNestedChildFolderDetails"];
    },
  },
  mounted() {
    this.emitter.on('unarchive-folder', (folder) => {
      this.unarchiveFolder(folder);
    }); 
    this.emitter.on('delete-archive-folder', (folder) => {
      this.openDeleteFolderModal(folder);
    }); 
  },
  methods: {
    generateKeys(node, prefix) {
    node.key = prefix;
    if (node.children && node.children.length > 0) {
      node.children.forEach((child, index) => {
        this.generateKeys(child, `${prefix}-${index}`);
      });
    }
  },
    openCreateFolderModal() {
      this.modalTitle = `Add a New Folder`
      this.visibleCreateNewFolderModal = true;  
      this.isNewFolder = true;
      this.folderParentId = "";
    },
    openCreateSubFolderModal(folder) {
      this.modalTitle = `Add a New Sub Folder`
      this.folderParentId = folder.id;
      this.isParentFolder = folder.isParentFolder
      this.visibleCreateNewFolderModal = true;  
    },
    closeNewFolderModal() {
      this.visibleCreateNewFolderModal = false;  
      this.visibleEditFolderModal = false;
    },
    setFolderUrl(folder) {
      this.$store.commit('folders/SET_ARCHIVE', false);
      // let tab = this.$route.params.class === 'personal' ? 'personal' : 'class';
      let tab = this.$route.params.class
      this.$router.push(`/workspaces/${tab}/${folder.id}`);
      this.createActivity(folder.id, 'folder', 'open');
    },
    async createNewFolder(folder) {
      try {
        this.isFolderBtnLoading = true;
        const payload = {
          name: folder.folderName,
          topic: folder.selectedTopicType,
          parent: this.folderParentId, //If exist then the sub folder will be create
        }
        // let createPersonalFolder = 'folders/createPersonalFolder'
        // let createClassFolder = 'folders/createClassFolder';
        // let apiCall = this.tabName === 'Class Folders' ? createClassFolder : createPersonalFolder;
        const response = await this.$store.dispatch('folders/createFolder', {'folderDetails':payload, "folderType":this.folderType});
        if (response.status === 201) {
          this.showMessageModal = true;
          this.message = {
            title: "folder created successfully.",
            type: "success",
          }

          if(!this.parents.find((parentId) => parentId === this.folderParentId ))
            this.parents.push(this.folderParentId)
          
          const folder = {
            folder: {
              name: response.data.name,
              id: response.data.id,
              isParentFolder: this.isNewFolder ? true : this.detectIfGrandChild(this.folderParentId) ? this.isParentFolder :  false,
              isChildFolder: this.isNewFolder ? false : this.detectIfGrandChild(this.folderParentId) ? false :  true,
              parentFolderId: this.folderParentId,
            },
            key: response.data.id,
            folder_tab: "",
            slots: { icon: "folderIcon" },
            children: []
          }
          if(this.isNewFolder){
            this.folderList.push(folder);
          } else if (folder.folder.isParentFolder) {
            const index = this.folderList.findIndex((findItem) => findItem.key === this.folderParentId);
            this.folderList[index].children.push(folder)
          } else if (folder.folder.isChildFolder) {        
            let parentIndex = -1
            this.folderList.forEach(grandParent => { 
              parentIndex = grandParent.children.findIndex(child => child.key === this.folderParentId)
              if (parentIndex >= 0) { 
                grandParent.children[parentIndex].children.push(folder)
                return 
              }
            })
          }
          this.$store.dispatch('folders/getFolderTree',this.folderType)
          // this.$route.params.class === 'personal' ?  this.$store.dispatch('folders/getPersonalFolderTree') : this.$store.dispatch('folders/getClassFolderTree', folder)
          this.reRender++;
        }
      } catch (err) {
        console.log(err);
      }finally {
        this.isFolderBtnLoading = false;
        this.folderParentId = "";
        this.isNewFolder = false;
        this.isChildFolder = false;
        this.isParentFolder = false;
        this.closeNewFolderModal();
      }
    },
    detectIfGrandChild(folderId) {
      return this.folderList.find(parentFolder=>parentFolder.key === folderId )
    },
    openEditFolderModal(folder) {
      this.folderToBeEdit = {
        name: folder.name,
        id: folder.id,
        isParentFolder: folder.isParentFolder,
        parentFolderId: folder.parentFolderId,
        folderChildId: folder.childFolderId,
        isChildFolder: folder.isChildFolder,
        topic: folder.topic
      }
      this.modalTitle = `Edit Folder`
      this.visibleEditFolderModal = true;
    },
    async UpdateFolder(folder) {
    try {
      this.isFolderBtnLoading = true;
      
      const payload = {
        folder_id: this.folderToBeEdit.id,
        name: folder.folderName,
        folderType: this.folderType,
        topic: folder.selectedTopicType
      };

      // const updatePersonalFolder = 'folders/editPersonalFolder';
      // const updateClassFolder = 'folders/editClassFolder';
      // const apiCall = this.tabName === 'Class Folders' ? updateClassFolder : updatePersonalFolder;

      const response = await this.$store.dispatch('folders/editFolder', payload);

      if (response.status === 200) {
        if (this.folderToBeEdit.isParentFolder && this.folderToBeEdit.parentFolderId === "") {
          const folderIndex = this.folderList.findIndex(obj => obj.folder.id === response.data.id);
          if (folderIndex !== -1) {
            this.folderList[folderIndex].folder.name = folder.folderName;
            this.folderList[folderIndex].folder.topic = folder.selectedTopicType;
            if (this.$route.params.id === this.folderToBeEdit.id) {
              this.parentFolder.folder.name = folder.folderName
              this.parentFolder.folder.topic = folder.selectedTopicType
            }
          }
        } else if (this.folderToBeEdit.isParentFolder) {
          const parentIndex = this.folderList.findIndex(folder => folder.folder.id === this.folderToBeEdit.parentFolderId);
          const childIndex = this.folderList[parentIndex].children.findIndex(child => child.folder.id === this.folderToBeEdit.id);

          if (childIndex !== -1) {
            this.folderList[parentIndex].children[childIndex].folder.name = folder.folderName;
            this.folderList[parentIndex].children[childIndex].folder.topic = folder.selectedTopicType;
            if (this.$route.params.id === this.folderToBeEdit.id) {
              this.childFolder.folder.name = folder.folderName
              this.childFolder.folder.topic = folder.selectedTopicType;
            }
          }
        } else if (this.folderToBeEdit.isChildFolder) {
          let parentIndex = -1
            this.folderList.forEach(grandParent => {
              parentIndex = grandParent.children.findIndex(child => child.folder.id === this.folderToBeEdit.folderChildId)
              if (parentIndex >= 0) {
                grandParent.children[parentIndex].children.find((nestedChild) => {
                  nestedChild.folder.name = folder.folderName
                  nestedChild.folder.topic = folder.selectedTopicType
                })
                if (this.$route.params.id === this.folderToBeEdit.id) {
                  this.nestedChildFolder.folder.name = folder.folderName
                  this.nestedChildFolder.folder.topic = folder.selectedTopicType;
                }
                return 
              }
            })
        }
        this.reRender++;
      }
      this.isFolderBtnLoading = false;
      this.closeNewFolderModal();
    } catch (err) {
      console.log(err);
    }
    },
    async archiveFolder(folder) {
      try {
        const payload = {
          id: folder.id,
          is_archived: true
        }
        const response = await this.$store.dispatch('folders/archiveOrUnchiveFolder', payload);
        if (response.status === 200) {
          const folderToPushInArchive = {
            child: [],
            id: folder.id,
            name: folder.name,
            owner: {},
            parent: null,
          }
          if (folder.parentFolderId) {
            const parentIndex = this.folderList.findIndex(idx => idx.key === folder.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child.key === folder.id);
            if (childIndex !== -1) {
              this.folderList[parentIndex].children.splice(childIndex, 1);
            }
          } else {
            const updatedFolderList = this.folderList.findIndex((val) => val.key === folder.id);
            if (updatedFolderList !== -1) {
              this.folderList.splice(updatedFolderList, 1);
              this.archivedFolders.push(folderToPushInArchive);
            }
          }
          this.reRender++;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async unarchiveFolder(folderToBeUnarchive) {
      try {
        const payload = {
          id: folderToBeUnarchive.id,
          is_archived: false
        }
        const response = await this.$store.dispatch('folders/archiveOrUnchiveFolder', payload);
        if (response.status === 200) {
          this.showMessageModal = true;
          this.message = {
            title: "Folder unarchived successfully.",
            type: "success",
          }
          const folder = {
            folder: {
              name: folderToBeUnarchive.name,
              id: folderToBeUnarchive.id,
              isParentFolder: true,
            },
            key: folderToBeUnarchive.id,
            folder_tab: "",
            slots: { icon: "folderIcon" },
            children: []
          }
          this.folderList.push(folder);
          this.reRender++;
          const updatedArchivedList = this.archivedFolders.findIndex((val) => val.id === folderToBeUnarchive.id);
          if (updatedArchivedList !== -1) {
            this.archivedFolders.splice(updatedArchivedList, 1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async openDeleteFolderModal(folder) {
      this.folderToBeRemoved = {
        id: folder.id,
        isParentFolder: !folder.parentFolderId,
        isChildFolder: folder.isParentFolder,
        isNestedFolder: folder.isChildFolder,
        parentFolderId: folder.parentFolderId,
        childFolderId: folder.childFolderId,
        isArchivedFolder: folder.isArchivedFolder
      }
      this.visibleRemoveFolderModal = true;
    },
    async deleteFolder() {
      try {
        this.onRemoveFolder = true;
        const payload = {
          folder_id : this.folderToBeRemoved.id
        }
        const deletePersonalFolder = 'folders/deletePersonalFolder';
        const deleteClassFolder = 'folders/deleteClassFolder';
        const apiCall = this.tabName === 'Class Folders' ? deleteClassFolder : deletePersonalFolder;

        const response = await this.$store.dispatch(apiCall, payload);
        if (response.status === 204) {
          this.visibleRemoveFolderModal = false;
          if (this.folderToBeRemoved.isParentFolder) {
            const updatedFolderList = this.folderList.findIndex((val) => val.key === this.folderToBeRemoved.id);
            if (updatedFolderList !== -1) {
              this.folderList.splice(updatedFolderList, 1);
            }
          } else if (this.folderToBeRemoved.isChildFolder) {
            const parentIndex = this.folderList.findIndex(folder => folder.key === this.folderToBeRemoved.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child.key === this.folderToBeRemoved.id);
            if (childIndex !== -1) {
              this.folderList[parentIndex].children.splice(childIndex, 1);
            }
          } else if (this.folderToBeRemoved.isNestedFolder) {
            const parentIndex = this.folderList.findIndex(folder => folder.key === this.folderToBeRemoved.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child.key === this.folderToBeRemoved.childFolderId);
            const nestedChildIndex = this.folderList[parentIndex].children[childIndex].children.findIndex(nestedChild => nestedChild.key === this.folderToBeRemoved.id);
            if (nestedChildIndex !== -1) {
              this.folderList[parentIndex].children[childIndex].children.splice(nestedChildIndex, 1);
            }
          }
          if (this.folderToBeRemoved.isArchivedFolder) {
            const updatedArchivedList = this.archivedFolders.findIndex((val) => val.id === this.folderToBeRemoved.id);
            if (updatedArchivedList !== -1) {
              this.archivedFolders.splice(updatedArchivedList, 1);
            }
          }
          this.reRender++;
          this.onRemoveFolder = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
  }
};
</script>

<style lang="scss">
.folder-tree-section {
  padding: 0.4rem 2.9rem 2.4rem;
  &__add-btn {
    background-color: $color-light-blue;
    border: none;
    outline: none;
    width: 100%;
    font-size: 1.4rem;
    font-family: $font-primary-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    height: 4rem;
    border-radius: 100px !important;
    margin-bottom: 2.4rem;
    cursor: pointer;
    .icon {
      color: $color-primary;
      font-size: 1.1rem;
      margin-right: 0.9rem;
      margin-top: -2px;
    }
  }
  &__message {
    font-size: 1.4rem;
    font-family: $font-primary-medium;
    margin-bottom: 0;
    text-align: center;
  }
  &__skeleton {
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
          li {
            height: 2rem;
            margin: 2rem 0;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .ant-tree > li:last-child {
    margin-bottom: 0 !important;
  }

  .ant-tree {
    height: calc(100vh - 43rem);
    overflow-y: auto;
    li {
      margin-bottom: 1.2rem !important;
    }
    .ant-tree-treenode-switcher-open,
    .ant-tree-treenode-switcher-close {
      padding: 1.2rem 1.4rem 0.2rem;
      background-color: #F5F5F5;
      border-radius: 0.8rem;
      margin-bottom: 0;
      .ant-tree-switcher {
        width: 2rem;
        height: 2rem;
        margin-top: 1px;

        .anticon {
          font-size: 1.6rem !important;
        }
      }

      .ant-tree-node-content-wrapper {
        padding: 0;
        margin-bottom: 1rem;
        width: calc(100% - 3rem);
        display: inline-flex;

        &:hover {
          background-color: rgb(217, 217, 217);
        }

        .ant-tree-icon__customize {
          width: 2.1rem;
          height: 2.1rem;
          margin: 0 1.2rem 0 0.8rem;

          img {
            width: 100%;
            height: 100%;
            margin-bottom: 0.4rem;
          }
        }

        .ant-tree-title {
          width: calc(100% - 5rem);
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            line-height: 1.8rem;
            margin-top: 0.1rem;
            color: $color-black;
            width: calc(100% - 4.5rem);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .add-folder-btn {
            width: fit-content;
            height: fit-content;
            margin-left: 1rem;
            line-height: normal;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: transparent;
            vertical-align: middle;
            margin-bottom: 2px;

            .icon {
              font-size: 1.2rem;
            }
          }
          .ant-dropdown-link {
            display: inline-block;
            color: $color-black;
            margin-left: auto;
            height: fit-content;
            width: fit-content;
            line-height: normal;
            .icon {
              font-size: 1.3rem;
            }
          }
          .drag-btn {
            display: flex;
            width: 1.3rem;
            flex-direction: column;
            border: none;
            outline: none;
            margin-left: 1.3rem;
            height: 1.4rem;
            width: 1.4rem;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            img {
              widows: 100%;
            }
          }
        }
      }

      .ant-tree-node-selected {
        background-color: transparent;
      }
      .ant-tree-child-tree {
        li {
          padding: 0;
          margin-bottom: 0 !important;
        }
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
  }
  &__message {
    font-size: 1.4rem;
    font-family: $font-primary;
    color: $color-black;
    opacity: 0.7;
  }
}
</style>
