<template>
  <a-row v-if="skeletonLoader" :gutter="22" >
    <a-col :xs="24" :sm="12" :md="8" :lg="xl" v-for="index in 4" :key="index">
      <ArticleCardSkeleton/>
    </a-col>
  </a-row>
  <a-row v-if="podcasts.length >= 1" :gutter="22">
    <button class="add-podcast-btn" @click="openAddVideoModal()">
      <i class="icon icon-add_plus"></i>
    </button>
  </a-row>
  <a-row v-if="!skeletonLoader && podcasts.length == 0 && isLoggedInUser" :gutter="22" >
    <button @click="openAddVideoModal()" class="add-content-btn"><i class="icon icon-add_plus"></i> Add content</button>
  </a-row>
  <div v-if="!skeletonLoader && podcasts.length == 0 && !isLoggedInUser" class="message">
    <p>No podcasts found</p>
  </div>
  <a-row v-else-if="!skeletonLoader" :gutter="[18, 18]">
    <a-col
      v-for="podcast in podcasts"
      :key="podcast"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="xl"
    > 
        <MediaCard 
          :videoUrl="podcast.link_content ? podcast.link_content : ''"
          :videoFileName="podcast.title"
          :id="podcast.id"
          :for="'Podcast'"
          :deleteBtn="false"
        />
    </a-col>
  </a-row>
  <UploadDocumentsModal 
   :visible="visibleUploadVideoModal"
   :folderId="this.$route.params.id"
   :modalTitle="'Podcast'"
   @closeModal="closeAddVideoModal()"
  />
</template>

<script>
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"
import UploadDocumentsModal from "./UploadDocumentModal.vue";
import MediaCard from "./MediaCard.vue";
import folderMixin from '../../mixins/folders.mixnin.js';
export default {
  data() {
    return {
      visibleUploadVideoModal: false,
      xl: 6
    };
  },
  mixins: [folderMixin],
  props: {
    collaborateToggle: {
      type: Boolean,
      default:()=>true
    },
    isTransitioning: {
      type: Boolean,
      default:()=>false
    }
  },
  components: {
    ArticleCardSkeleton,
    UploadDocumentsModal,
    MediaCard
  },
  watch: {
    collaborateToggle: {
      handler() {
        this.updateXl();
      },
      immediate: true
    },
    isTransitioning: {
      handler() {
        this.updateXl();
      },
      immediate: true
    }
  },
  computed: {
    skeletonLoader() {
      return this.$store.getters["folders/getFolderPodcastContentLoader"];
    },
    podcasts() {
      return this.$store.getters["folders/getFolderPodcastContent"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  methods: {
    openAddVideoModal() {
      this.visibleUploadVideoModal = true;
    },
    closeAddVideoModal(){
      this.visibleUploadVideoModal = false;
    },
    updateXl() {
      if (this.collaborateToggle && this.isTransitioning) {
        this.xl = 8;
      } else if (!this.collaborateToggle && !this.isTransitioning){
        this.xl = 6;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-podcast-btn {
  background-color: $color-primary;
  height: 4.2rem;
  width: 4.2rem;
  border: none;
  outline: none;
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  .icon {
    color: $color-white;
    font-size: 1.6rem;
  }
}
.add-content-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: $font-primary;
  height: fit-content;
  line-height: normal;
  padding: 0;
  border: none;
  outline: none;
  color: $color-black;
  margin: 5rem auto 0;
  cursor: pointer;
  .icon {
    font-size: 1.4rem;
    margin-right: 1rem;
    margin-bottom: 0.1rem;
  }
}
.message {
  p {
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-bottom: 0;
    line-height: 2.3rem;
    color: $color-black;
    margin-bottom: 3rem;
    text-align: center;
  }
}
</style>
