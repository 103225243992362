<template>
  <div class="archive-folder-section">
    <h1 class="archive-folder-section__title">Archives</h1>
    <p v-if="archivedFolders.length == 0" class="archive-folder-section__message">
      No Folders found
    </p>
    <a-row v-else :gutter="[18, 18]">
      <FolderCards />
    </a-row>
  </div>
</template>

<script>
import FolderCards from "./FolderCards.vue";
export default {
  data() {
    return {};
  },
  components: {
    FolderCards,
  },
  computed: {
    archivedFolders() {
      return this.$store.getters["folders/getArchivedFolder"];
    },
  },
};
</script>

<style lang="scss">
.archive-folder-section {
  padding: 4rem 5.4rem;
  &__title {
    font-size: 2.8rem;
    font-family: $font-primary-bold;
    margin-bottom: 2rem;
    line-height: 3.5rem;
  }
  &__message {
    font-size: 1.6rem;
    font-family: $font-primary;
    line-height: 2.3rem;
    color: $color-black;
    margin: 0 auto;
    width: fit-content;
    margin-top: 6.2rem;
  }
}
</style>
